@font-face {
  font-family: 'PROXON';
  src: url('/public/fonts/PROXON.ttf'), url('/public/fonts/PROXON.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'OUTFIT';
  src: url('/public/fonts/Outfit-Regular.ttf'), url('/public/fonts/Outfit-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

h1{
  font-family: 'PROXON', sans-serif;
}
.App {
  font-family: 'OUTFIT', sans-serif;
}

.head-txt h2{
  text-transform: uppercase;
  font-weight: bold;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.navbar-toggler-icon {
  background-color: #1eff49;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.uline{
  text-underline-offset: 10px;
  padding: 5px 15px;
  color: #fff;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-color: #1BFF49;
}

#slider, #faq, #stake, #stake2, #stake3, #roadmap, #referral, #footer-nav {
  background-color: #181818;
}

#footer-nav a{
  color: #fff;
}

#programs{
  background-color: #ECECEC;
}


.bg_header{
  background: url(/public/bg_head.svg);
  background-color: #111111;
  position: relative;

  height: auto;
  min-height: 450px;
}

#header-nav .nav-link {
color: #fff;
}


#stake .txt {
  background: url(/public/bg_stake.svg);
  background-repeat: no-repeat;
  background-position: center right;
  background-size: 25%;
}
#stake .txt div{
  text-underline-offset: 10px;
  padding: 5px 15px;
}

#stake .txt div:nth-child(even){
  color: #fff;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-color: #1BFF49;
}

#stake .txt div:nth-child(odd){
  color: #fff;
  text-align: right;
  opacity: 0.6;
}

#stake2, #stake3{
  color: #fff;
}
#stake3 ul{
  list-style: square;
}
#stake3 li {
  color: #1BFF49; /* bullet color */
}
#stake3 li span {
  color: #fff; /* text color */
}

#slider{

}
.allpic{
  width:20%;
  padding: 15px 15px 135px 15px;
  float:right;
  overflow:hidden;
  position: relative;
  -webkit-transition: all .5s ease-out;
  -o-transition: all .5s ease-out;
  -moz-transition: all .5s ease-out;
  transition: all .5s ease-out;
}

#slider .allpic:nth-child(3){
  background-color: #C90085;
}
#slider .allpic:nth-child(2){
  background-color: #1BFF49;
}
#slider .allpic:nth-child(3):before{
  position:absolute;
  width: 100%;
  height: 100%;
  left: 25%;
  top: 40%;
  content: "";
  background-image:  url(/public/st1.svg);
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 1;
}
#slider .allpic:nth-child(1):before{
  position:absolute;
  width: 100%;
  height: 100%;
  left: 25%;
  top: 40%;
  content: "";
  background-image:  url(/public/st3.svg);
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 0.8;
}
#slider .allpic:nth-child(2):before{
  position:absolute;
  width: 100%;
  height: 100%;
  left: 25%;
  top: 40%;
  content: "";
  background-image:  url(/public/st2.svg);
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 0.8;
}
#slider .allpic:nth-child(1){
  background-color: #2E8EFD;
}

.allpic h3, .allpic p{
  white-space: nowrap;
}
.allpic h3{
  font-weight: bold;
}

.allpic:hover {
  width:205px;
}

.allpic::after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  bottom: -40px;
  left: -40px;
  border: 40px solid;
  transform: rotate(45deg);
  opacity: 1;
  transition: .5s;
  border-color: #181818 transparent transparent transparent;
}

#faq .accordion-header .accordion-button {
  background: #1f1f1f;
  color: #fff;
  font-weight: 600;

}
#faq .accordion-header .accordion-button:after{
background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%231BFF49'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

#faq .accordion-item:first-of-type>.accordion-header .accordion-button{
  border-radius: 0;
}

#faq .accordion-item{
  border: 1px #181818 solid;
}
#faq  .accordion-button:not(.collapsed) {
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 #181818;
}

.accordion-body{
  background: #1f1f1f;
  color: #ffffff8c;
}


#stake3 .bt1, #header-nav .bt1 {
  position: relative;
  background: linear-gradient(#1BFF49, #00B123);
  padding: 5px 50px;
  border: none;
  color: #fff;
  transition: 1s;
}
#header-nav .bt1 {
  color: #181818 !important;
}
#stake3 .bt1:hover{
  background: linear-gradient(#00B123, #1BFF49);
  transition: 1s;
}
#stake3 .bt1::after, #header-nav .bt1::after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  bottom: -10px;
  left: -10px;
  border: 10px solid;
  transform: rotate(45deg);
  opacity: 1;
  transition: .5s;
  border-color: #181818 transparent transparent transparent;
}
#stake3 .bt1::before, #header-nav .bt1::before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  top: -10px;
  right: -10px;
  border: 10px solid;
  transform: rotate(45deg);
  opacity: 1;
  transition: .5s;
  border-color: transparent transparent #181818 transparent;;
}



#stake2 .bt1{
  position: relative;
  background: linear-gradient(#1BFF49, #00B123);
  padding: 5px 50px;
  border: none;
  color: #111;
  display: inline-block;
  margin: 0px auto;
}



#stake2 .bt1::after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  bottom: -10px;
  left: -10px;
  border: 10px solid;
  transform: rotate(45deg);
  opacity: 1;
  transition: .5s;
  border-color: #181818 transparent transparent transparent;
}
#stake2 .bt1::before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  bottom: -10px;
  right: -10px;
  border: 10px solid;
  transform: rotate(45deg);
  opacity: 1;
  transition: .5s;
  border-color: transparent transparent transparent #181818;
}

#stake2 .txt div:nth-child(odd){
  position: relative;
  background: linear-gradient(#1BFF49, #00B123);
  padding: 10px 50px;
  border: none;
  color: #111;
  display: inline-block;
  margin: 10px auto;
  font-weight: 600;
  line-height: 1;
  text-align: center;
}
#stake2 .txt div:nth-child(odd)::after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  bottom: -10px;
  left: -10px;
  border: 10px solid;
  transform: rotate(45deg);
  opacity: 1;
  transition: .5s;
  border-color: #181818 transparent transparent transparent;
}

#stake2 .log{
  position: relative;
  margin-top: 20px;
}

#stake2 .log::after{
content: "";
width: 30px;
height: 30px;
background: url(/public/AIST_logo.svg);
position: absolute;
right: -25px;
background-size: contain;
background-repeat: no-repeat;
}

.price{
position: relative;
padding-bottom: 2em;
}

.price::after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  bottom: calc(-2em + -1px);
  left: calc(-2em + -1px);
  border: 2em solid;
  transform: rotate(45deg);
  opacity: 1;
  transition: .5s;
  border-color: #ECECEC transparent transparent transparent;
}
.price::before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  top: calc(-2em + -1px);
  right: calc(-2em + -1px);
  border: 2em solid;
  transform: rotate(45deg);
  opacity: 1;
  transition: .5s;
  border-color: transparent transparent #ECECEC transparent;
}

.list div:nth-child(1) .price{
  background: #FFF;
}

.list div:nth-child(2) .price{
  background: #1BFF49;
}

.list div:nth-child(3) .price{
  background: #C90085;
  color: #fff;
}

.check{
  padding: 5px 7px;
  border: 1px solid;
  border-radius: 50%;
  font-size: 1em;
  line-height: 1em;
}


.price i{
  font-style: normal;
  float: right;
  font-weight: 600;
}

#programs .zag{
  border-bottom: 1px #111 solid;
  padding-bottom: 0.5em;
  margin-bottom: 1em;
}


.text-green{
    color: #1BFF49 !important;
}
#slider{

}
.slide::after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  bottom:  calc(-2em + -1px);
  left: calc(-2em + 0px);
  border: 2em solid;
  transform: rotate(45deg);
  opacity: 1;
  transition: .5s;
  border-color: #181818 transparent transparent transparent;
}
.slide::before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  top:  calc(-2em + -1px);
  right: calc(-2em + -1px);
  border: 2em solid;
  transform: rotate(45deg);
  opacity: 1;
  transition: .5s;
  border-color: transparent transparent #181818 transparent;
}
.slide{
  background: #1BFF49;
  position: relative;
  box-shadow: -10px 10px 20px #1818188f;
  padding: 1em;
  height: 270px;
}

.slick-slider::after{
  content: "";
  width: 100%;
  height: 40px;
  background: linear-gradient(0deg, #181818, #00000082);
  position: absolute;
  bottom: -10px;
  z-index: 1;
}
.sl1{
  background: #00991F;
  color: #fff;
}

.sl2{
  background: #09BB2D;
  color: #fff;
}

.sl3{
  background: #F5FFE6;
}

.sl4{
  background: #D2EC34;
}

.sl5{
  background: #C90085;
  color: #fff;
}

.sl6{
  background: #52FFB6;
}

.sl7{
  background: #09BB2D;
  color: #fff;
}

.sl8{
  background: #F5FFE6;
}

.sl9{
  background: #00C2FF;
}

.sl10{
  background: #F5FFE6;
}

.sl11{
  background: #FFB800;
}

.slide-bg{
  width: 90%;
  background-repeat: no-repeat !important;
  background-size: contain !important;
  margin: 0px auto;
  
}

.sl1 .slide-bg{
  background: url(/public/1.svg);
  height: 10vh;
}

.sl2 .slide-bg{
  background: url(/public/2.svg);
  height: 16vh;
}

.sl3 .slide-bg{
  background: url(/public/3.svg);
  height: 18vh;
  background-position: center;
}

.sl4 .slide-bg{
  background: url(/public/4.svg);
  height: 10vh;
  background-position: center;
}

.sl5 .slide-bg{
  background: url(/public/5.svg);
  height: 10vh;
}

.sl6 .slide-bg{
  background: url(/public/6.svg);
  height: 10vh;
}

.sl7 .slide-bg{
  background: url(/public/7.svg);
  height: 10vh;
}

.sl8 .slide-bg{
  background: url(/public/8.svg);
  height: 10vh;
}

.sl9 .slide-bg{
  background: url(/public/9.svg);
  height: 10vh;
}

.sl10 .slide-bg{
  background: url(/public/10.svg);
  height: 10vh;
}

.sl11 .slide-bg{
  background: url(/public/11.svg);
  height: 10vh;
}


.circles{
  position: absolute;
  height: 100%;
  width: calc(100% - 150px);
  z-index: 0;
}
#header-nav{
  position: relative;
  z-index: 1;
}
.head-txt{
height: calc(100% - 80px);
}

.bg_header h1, .bg_header h2,  .bg_header .hstack{
  position: relative;
  z-index: 1;
}

.bg_header li{
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  animation: animate 25s linear infinite;
  bottom: -150px;
  
}

.bg_header li:nth-child(1){
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
  background: url(/public/l1.svg);
  background-repeat: no-repeat;
  background-size: contain;
}


.bg_header li:nth-child(2){
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
  background: url(/public/l1.svg);
  background-repeat: no-repeat;
  background-size: contain;
}

.bg_header li:nth-child(3){
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
  background: url(/public/l1.svg);
  background-repeat: no-repeat;
  background-size: contain;
}

.bg_header li:nth-child(4){
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
  background: url(/public/l1.svg);
  background-repeat: no-repeat;
  background-size: contain;
}

.bg_header li:nth-child(5){
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
  background: url(/public/l1.svg);
  background-repeat: no-repeat;
  background-size: contain;
}

.bg_header li:nth-child(6){
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
  background: url(/public/l1.svg);
  background-repeat: no-repeat;
  background-size: contain;
}

.bg_header li:nth-child(7){
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
  background: url(/public/l1.svg);
  background-repeat: no-repeat;
  background-size: contain;
}

.bg_header li:nth-child(8){
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
  background: url(/public/l1.svg);
  background-repeat: no-repeat;
  background-size: contain;
}

.bg_header li:nth-child(9){
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
  background: url(/public/l1.svg);
  background-repeat: no-repeat;
  background-size: contain;
}

.bg_header li:nth-child(10){
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
  background: url(/public/l1.svg);
  background-repeat: no-repeat;
  background-size: contain;
}

.head-txt .btn1{
  position: relative;
  z-index: 1;

}

@keyframes animate {

  0%{
      transform: translateY(0) rotate(0deg);
      opacity: 1;
      border-radius: 0;
  }

  100%{
      transform: translateY(-500px) rotate(720deg);
      opacity: 0;
      border-radius: 50%;
  }

}

#mainbut .toast {
  width: 100%;
  border: none;
  box-shadow: none;
  background: none;
}


#mainbut .toast-body .bt:nth-child(1) div::after,
#mainbut .toast-body .bt:nth-child(2) div::after,
#mainbut .toast-body .bt:nth-child(5) div::after,
#mainbut .toast-body .bt:nth-child(6) div::after  {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  bottom: -11px;
  left: -11px;
  border: 10px solid;
  transform: rotate(45deg);
  opacity: 1;
  transition: .5s;
  border-color: #181818 transparent transparent transparent;
}
#mainbut .toast-body .bt:nth-child(3) div::after,
#mainbut .toast-body .bt:nth-child(4) div::after,
#mainbut .toast-body .bt:nth-child(7) div::after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  bottom: -11px;
  right: -11px;
  border: 10px solid;
  transform: rotate(45deg);
  opacity: 1;
  transition: .5s;
  border-color: transparent transparent transparent #181818;
}
#mainbut .toast-body .bt:nth-child(1) div:before,
#mainbut .toast-body .bt:nth-child(2) div:before,
#mainbut .toast-body .bt:nth-child(5) div:before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  bottom: -20px;
  right: 25%;
  border: 10px solid;
  transform: rotate(90deg);
  opacity: 1;
  transition: .5s;
  border-color: transparent transparent transparent #ffffff;
}
#mainbut .toast-body .bt:nth-child(6) div:before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  bottom: -20px;
  right: calc(50% - 10px);
  border: 10px solid;
  transform: rotate(90deg);
  opacity: 1;
  transition: .5s;
  border-color: transparent transparent transparent #ffffff;
}
#mainbut .toast-body .bt:nth-child(3) div:before,
#mainbut .toast-body .bt:nth-child(4) div:before,
#mainbut .toast-body .bt:nth-child(7) div:before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  bottom: -20px;
  left: 25%;
  border: 10px solid;
  transform: rotate(90deg);
  opacity: 1;
  transition: .5s;
  border-color: transparent transparent transparent #ffffff;
}
#mainbut .toast-body .bt div, #mainbut .toast-body .bt0 div{
  background: #fff;
  position: relative;
  padding: 1em 2em;
  margin-bottom: 20px;
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
}

#mainbut .toast-body .bt0:nth-child(1) div::after,
#mainbut .toast-body .bt0:nth-child(2) div::after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  bottom: -11px;
  left: -11px;
  border: 10px solid;
  transform: rotate(45deg);
  opacity: 1;
  transition: .5s;
  border-color: #181818 transparent transparent transparent;
}
#mainbut .toast-body .bt0:nth-child(3) div::after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  bottom: -11px;
  right: -11px;
  border: 10px solid;
  transform: rotate(45deg);
  opacity: 1;
  transition: .5s;
  border-color: transparent transparent transparent #181818;
}
#mainbut .toast-body .bt0:nth-child(2) div:before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  top: -20px;
  right: calc(50% - 10px);
  border: 10px solid;
  transform: rotate(270deg);
  opacity: 1;
  transition: .5s;
  border-color: transparent transparent transparent #ffffff;
}
#mainbut .toast-body .bt0:nth-child(1) div:before{
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  top: -20px;
  right: 25%;
  border: 10px solid;
  transform: rotate(270deg);
  opacity: 1;
  transition: .5s;
  border-color: transparent transparent transparent #ffffff;
}
#mainbut .toast-body .bt0:nth-child(3) div:before{
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  top: -20px;
  left: 25%;
  border: 10px solid;
  transform: rotate(270deg);
  opacity: 1;
  transition: .5s;
  border-color: transparent transparent transparent #ffffff;
}

#mainbut .bt1{
  background: #1BFF49;
  position: relative;
  padding: 1em 2em;
  margin-bottom: 20px;
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  width: 300px;
}

#mainbut .bt1::after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  top: -11px;
  left: -11px;
  border: 10px solid;
  transform: rotate(45deg);
  opacity: 1;
  transition: .5s;
  border-color: transparent #181818  transparent transparent;
}
#mainbut .bt1::before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  top: -11px;
  right: -11px;
  border: 10px solid;
  transform: rotate(45deg);
  opacity: 1;
  transition: .5s;
  border-color: transparent transparent #181818 transparent;
}

#mainbut .bt2{
  background: #C90085;
  position: relative;
  padding: 1em 2em;
  margin-bottom: 20px;
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  width: 300px;
  color: #fff;
}

#mainbut .bt2::after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  bottom: -11px;
  left: -11px;
  border: 10px solid;
  transform: rotate(45deg);
  opacity: 1;
  transition: .5s;
  border-color: #181818 transparent transparent transparent;
}
#mainbut .bt2::before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  bottom: -11px;
  right: -11px;
  border: 10px solid;
  transform: rotate(45deg);
  opacity: 1;
  transition: .5s;
  border-color: transparent transparent transparent #181818;
}

.chevroncont {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
  z-index: 0;
}

.chevrondown {
  position: absolute;
  width: 1rem;
  height: 0.1rem;
  opacity: 0;
  transform: scale(0.3);
  -webkit-animation: move-chevron 3s ease-out infinite;
          animation: move-chevron 3s ease-out infinite;
          z-index: 1;
}

.chevronup {
  position: absolute;
  width: 1rem;
  height: 0.1rem;
  opacity: 0;
  transform: scale(0.3);
  -webkit-animation: move-chevronup 3s ease-out infinite;
          animation: move-chevronup 3s ease-out infinite;
          z-index: 1;
}

.chevrondown:first-child {
  -webkit-animation: move-chevron 3s ease-out 1s infinite;
          animation: move-chevron 3s ease-out 1s infinite;
}

.chevronup:first-child {
  -webkit-animation: move-chevronup 3s ease-out 1s infinite;
          animation: move-chevronup 3s ease-out 1s infinite;
}

.chevrondown:nth-child(2) {
  -webkit-animation: move-chevron 3s ease-out 2s infinite;
          animation: move-chevron 3s ease-out 2s infinite;
}

.chevronup:nth-child(2) {
  -webkit-animation: move-chevronup 3s ease-out 2s infinite;
          animation: move-chevronup 3s ease-out 2s infinite;
}

.chevrondown:before,
.chevrondown:after,
.chevronup:before,
.chevronup:after {
  content: "";
  position: absolute;
  top: 20px;
  height: 100%;
  width: 50%;
  background: #fff;
}

.chevrondown:before {
  left: 0;
  transform: skewY(30deg);
}

.chevronup:before {
  left: 0;
  transform: skewY(-30deg);
}

.chevrondown:after {
  right: 0;
  width: 50%;
  transform: skewY(-30deg);
}

.chevronup:after {
  right: 0;
  width: 50%;
  transform: skewY(30deg);
}

@-webkit-keyframes move-chevron {
  25% {
    opacity: 1;
  }
  33.3% {
    opacity: 1;
    transform: translateY(1.28rem);
  }
  66.6% {
    opacity: 1;
    transform: translateY(2.12rem);
  }
  100% {
    opacity: 0;
    transform: translateY(3.8rem) scale(0.5);
  }
}

@keyframes move-chevron {
  25% {
    opacity: 1;
  }
  33.3% {
    opacity: 1;
    transform: translateY(1.28rem);
  }
  66.6% {
    opacity: 1;
    transform: translateY(2.12rem);
  }
  100% {
    opacity: 0;
    transform: translateY(3.8rem) scale(0.5);
  }
}
@-webkit-keyframes move-chevronup {
  25% {
    opacity: 1;
  }
  33.3% {
    opacity: 1;
    transform: translateY(-1.28rem);
  }
  66.6% {
    opacity: 1;
    transform: translateY(-2.12rem);
  }
  100% {
    opacity: 0;
    transform: translateY(-3.8rem) scale(0.5);
  }
}
@keyframes move-chevronup {
  25% {
    opacity: 1;
  }
  33.3% {
    opacity: 1;
    transform: translateY(-1.28rem);
  }
  66.6% {
    opacity: 1;
    transform: translateY(-2.12rem);
  }
  100% {
    opacity: 0;
    transform: translateY(-3.8rem) scale(0.5);
  }
}

.soc{
  margin-bottom: 20px;
}
.soc .btn{
  width: auto;
  color: #181818 !important;
  background: #09BB2D;
  border: none;
  margin: 0px 10px;
  border-radius: 4px;
  font-weight: bold;
}

.soc .btn:hover{
  background: #fff;
}

.soc i{
  width: 15px;
  color: #181818;
}


html,body{
  overflow-x: hidden
  }


  @media (max-width: 768px) { 

    #mainbut .toast-body .bt:nth-child(1) div:before,
    #mainbut .toast-body .bt:nth-child(2) div:before,
    #mainbut .toast-body .bt:nth-child(3) div:before,
    #mainbut .toast-body .bt:nth-child(4) div:before,
    #mainbut .toast-body .bt:nth-child(5) div:before,
    #mainbut .toast-body .bt:nth-child(6) div:before,
    #mainbut .toast-body .bt:nth-child(7) div:before   {
      left: calc(50% - 10px) !important;
    }
    #mainbut .toast-body .bt0:nth-child(1) div:before,
    #mainbut .toast-body .bt0:nth-child(2) div:before,
    #mainbut .toast-body .bt0:nth-child(3) div:before  {
      left: calc(50% - 10px) !important;
    }

   }